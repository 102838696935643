<template>
    <div class="page">
        <div class="head">
            <span>在线订单</span>
        </div>
        <div class="subtitle">
            <div class="subtitle-item" style="width: 100%;background-color: #fff;padding:20px 0">
                <div><Input @on-change="inputchange" v-model="storePrams.keywords" placeholder="请选择姓名、电话、车牌号、初始地、目的地" style="width: 24vw"><Icon type="md-search" slot="suffix" /></Input></div>
                <div><span class="input-span">送达时间</span>
                    <DatePicker type="daterange" @on-change="chooseDate" placement="bottom-end" placeholder="请选择送达时间" style="width:16vw"></DatePicker></div>
                <div>
                    <span class="input-span">支付状态</span>
                    <Select v-model="storePrams.pay_status" style="width:10vw">
                        <Option v-for="item in payList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </div>
                <div>
                    <span class="input-span">取车方式</span>
                    <Select v-model="storePrams.type" style="width:10vw">
                        <Option v-for="item in carList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </div>
                <div><Button type="primary" @click="searchSubmit">搜索</Button></div>
            </div>
        </div>
        <div class="content">
            <div style="width: 100%;background-color: #fff;padding: 20px;">
                <Table :columns="columnsTable" :data="dataTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row, index }" slot="car">
                       <div>{{row.car.name}}</div>
                    </template>
                    <template slot-scope="{ row, index }" slot="goTime">
                       <div v-if="row.service_time" class="time_box" @click="showTimeModal(row)">{{row.service_time}}</div>
                       <div v-else class="time_box" @click="showTimeModal(row)">请选择送达时间</div>
                    </template>
                    <template slot-scope="{ row, index }" slot="type">
                        {{ row.type == 1 ? '上门取车' : '送车上门' }}
                    </template>
                    <template slot-scope="{ row, index }" slot="desc">
                        <div v-if="row.type == 1">
                            取车时间：{{ row.collection_time}}；
                            取车地址：{{ row.collection_address}}
                        </div>
                    </template>
                    <template slot-scope="{ row, index }" slot="pay_status">
                        <div v-if="row.status == 0">{{row.pay_status}}</div>
                        <div v-if="row.status == 1">已完成</div>
                    </template>
                    <template slot-scope="{ row, index }" slot="handle">
                        <span class="table_text" @click="showMoneyModal(row)">改价</span>
                        <span class="table_text" @click="showInfoModal(row)">物流信息</span>
                        <span class="table_text" @click="submit(row)">完成</span>
                    </template>
                </Table>
                <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" @on-change="pageChange" :total="total" show-elevator show-total />
            </div>
        </div>
        <Modal v-model="timeModal" title="预计送达时间" :mask-closable="false">
            <div style="text-align:center">
                <span style="margin-right: 20px;">送达时间</span>
                <DatePicker type="datetime" placeholder="请选择送达时间" style="width: 200px" v-model="dateValue" @on-change="timeDateChange"/>
            </div>
            <div slot="footer">
                <Button @click="closeTimeModal">取消</Button>
                <Button type="primary" @click="submitTimeModal">保存</Button>
            </div>
        </Modal>
        <Modal v-model="moneyModal" title="改价记录" width="60%" :mask-closable="false">
            <div style="margin-bottom: 20px;"><Button type="primary" @click="openModal">改价</Button></div>
            <div >
                <div class="box_auto_heigt">
                    <Table :columns="columnsMoneyTable" :data="dataMoneyTable" border>
                        <template slot-scope="{ index }" slot="id">
                            <div>{{ index + 1 }}</div>
                        </template>
                    </Table>
                </div>
                <Page style="" class="Page page_cla" :current="modifyMoneyData.page" :page-size="modifyMoneyData.limit" @on-change="pageMoneyChange" :total="dataMoneyTotal" show-elevator show-total />
            </div>
            <div slot="footer"></div>
        </Modal>
        <Modal v-model="infoModal" title="物流信息" width="70%" :mask-closable="false">
            <div style="margin-bottom: 20px;"><Button type="primary" @click="saveInfoModal">录入</Button></div>
            <div>
                <div class="box_auto_heigt">
                    <Table :columns="columnsInfoTable" :data="dataInfoTable" border>
                        <template slot-scope="{ index }" slot="id">
                            <div>{{ index + 1 }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="type">
                            <span class="infoModal_text"  @click="openInfoModal(row)">修改</span>
                            <span class="infoModal_text" @click="delLogModal(row)">删除</span>
                        </template>
                    </Table>
                </div>
                <Page class="Page page_cla" :current="logPrams.page" :page-size="logPrams.limit" @on-change="pageLogChange" :total="logtotal" show-elevator show-total />
            </div>
            <div slot="footer"></div>
        </Modal>
        <Modal v-model="changeModal" title="改价" :mask-closable="false">
            <div style="text-align:center">
                <span style="margin-right: 20px;">金额</span>
                <InputNumber placeholder="请输入金额" v-model="newMoney"  :min="0"   style="width: 200px"></InputNumber>
            </div>
            <div slot="footer">
                <Button @click="closechangeModal">取消</Button>
                <Button type="primary" @click="submitChangeModal">确认</Button>
            </div>
        </Modal>
        <Modal v-model="enterModal" title="录入" :mask-closable="false">
            <div>
                <Form ref="formInline" :model="formInline" :label-width="130">
                    <FormItem prop="state" label="状态">
                        <Input type="text" v-model="formInline.status" placeholder="请输入状态" style="width: 300px;"></Input>
                    </FormItem>
                    <FormItem prop="time" label="时间">
                        <DatePicker type="datetime" placeholder="请选择时间" style="width: 300px" v-model="formInline.logistics_time" @on-change="timeDateModalChange"/>
                    </FormItem>
                    <FormItem prop="phone" label="联系电话">
                        <Input type="text" v-model="formInline.phone" placeholder="请输入联系电话" style="width: 300px;"></Input>
                    </FormItem>
                    <FormItem prop="info" label="物流信息">
                        <Input v-model="formInline.infos" maxlength="100" show-word-limit type="textarea" placeholder="请输入物流信息" style="width: 300px" />
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="cancelEnter">取消</Button>
                <Button type="primary" @click="saveEnterModal">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { orderList,modifyMoney,moneyList,modifyTime,modifyStatus,addLog,listLog,delLog } from "@/api/index";
    export default {
        data() {
            return {
                dateValue:'',
                timeModal: false,
                moneyModal: false,
                infoModal: false,
                newMoney: 0,
                modifyMoneyData:{
                    id: '',
                    limit: 30,
                    page: 1 ,
                },
                logtotal: 0,
                dataMoneyTotal:0,
                changeModal: false,
                enterModal: false,
                searchContent:'',
                modelPay: '',
                payList:[
                    {id: -1,name: '所有'},
                    {id: 0,name: '未支付'},
                    {id: 1 ,name: '已支付'}
                ],
                modelCar: '',
                carList:[
                    {id:-1,name: '所有'},
                    {id: 1,name: '上门取车'},
                    {id: 2,name: '送车上门'}
                ],
                columnsTable: [
                    {title: '#',slot: "id",align:'center',width:'80px'},
                    {title: '目的地',key: 'destination_address',align:'center',width:'150px'},
                    {title: '初始地',key: 'initially_address',align:'center',width:'150px'},
                    {title: '车辆',slot: 'car',align:'center',width:'120px'},
                    {title: '姓名',key: 'name',align:'center',width:'100px'},
                    {title: '手机号',key: 'phone',align:'center',width:'130px'},
                    {title: '车牌号',key: 'car_number',align:'center',width:'110px'},
                    {title: '类型',slot: 'type',align:'center',width:'100px'},
                    {title: '备注',slot: 'desc',align:'center',width:'235px'},
                    {title: '金额',key: 'money',align:'center',width:'100px'},
                    {title: '状态',slot: 'pay_status',align:'center',width:'100px'},
                    {title: '预计送达时间',slot: 'goTime',align:'center',width:'170px'},
                    {title: '操作',slot: 'handle',align:'center',width:'180px',fixed:'right'},
                ],
                dataTable: [
                    {
                        initially_address: '',
                        destination_address: '',
                        car: {},
                        name: '',
                        phone: '',
                        car_number: '',
                        type: '',
                        desc: '',
                        money: '',
                        pay_status: '',
                        id:'',
                    },
                ],
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                    pay_status:-1,
                    type:-1,
                    start_date:'',
                    end_date:'',
                    keywords:'',
                },
                logPrams: {
                    id: '',
                    page: 1,
                    limit: 30,
                },
                columnsMoneyTable: [
                    {title: '#',slot: "id",align:'center'},
                    {title: '操作时间',key: 'created_at',align:'center'},
                    {title: '金额',key: 'money',align:'center'},
                    {title: '类型',key: 'type',align:'center'},
                    {title: '状态',key: 'pay_status',align:'center'},
                ],
                dataMoneyTable:[
                    {
                        time:'',
                        money: 0,
                        type: '',
                    },
                ],
                columnsInfoTable: [
                    {title: '#',slot: "id",align:'center'},
                    {title: '物流状态',key: 'status',align:'center'},
                    {title: '时间',key: 'logistics_time',align:'center'},
                    {title: '信息',key: 'infos',align:'center'},
                    {title: '电话',key: 'phone',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataInfoTable:[
                    {
                        status:'',
                        logistics_time:'',
                        infos: '',
                        phone: '',
                    },
                ],
                formInline: {
                    status: '',
                    phone: '',
                    infos: '',
                    order_id:'',
                    logistics_time:'',
                    id:'',
                },
                timeId:'',
                order_id:''
            }
        },    
        created(){
            this.orderList()
        },
        methods: {
            //取消按钮
            cancelEnter(){
                this.enterModal = false
            },
            //物流信息删除
            delLogModal(row){
                delLog({id:row.id}).then(res=>{ 
                    this.listLog()     
                    this.$Message.success(res.msg)                    
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            timeDateChange(value){
                this.dateValue = value
            },
            
            chooseTimeDate(e){
                console.log('e',e)
            },
            chooseDate(e){
                console.log(e)
                if(e[0]){
                    this.storePrams.start_date = e[0]
                    this.storePrams.end_date = e[1]
                }else {
                    this.storePrams.start_date = ''
                    this.storePrams.end_date = ''
                }
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.orderList()
            },
          
            // 列表
            orderList(){
                orderList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            inputchange(e) {
                console.log('inputchange',e)
            },
            searchSubmit(){
                this.orderList()
            },
            showTimeModal(row){
                this.timeModal = true
                this.dateValue = row.service_time
                this.timeId = row.id
            },
            closeTimeModal(){
                this.timeModal = false
            },
            // 日期选择器保存
            submitTimeModal(){
                if(!this.dateValue){
                    this.$Message.error('请选择送达时间')
                    return
                }
                let data = {
                    id: this.timeId,
                    service_time:this.dateValue
                }
                modifyTime(data).then(res=>{
                    this.timeModal = false
                    this.orderList()
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            //改价
            showMoneyModal(row) {
                console.log('showMoneyModal',row)
                this.modifyMoneyData.id = row.id
                this.moneyModal = true
                this.moneyList()                
            },
            pageMoneyChange(index){
                this.modifyMoneyData.page = index;
                this.moneyList()
            },
            pageLogChange(index){
                this.logPrams.page = index;
                this.listLog()
            },
            moneyList(){                
                moneyList(this.modifyMoneyData).then(res=>{               
                    this.dataMoneyTable = res.data.data
                    this.dataMoneyTotal = res.data.total
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            openModal(index) {
                this.newMoney = null
                this.changeModal = true
            },
            closechangeModal(){
                this.changeModal = false
            },
            submitChangeModal(){
                if(!this.newMoney&&this.newMoney!==0){
                    this.$Message.error('请输入金额')
                    return
                }
                let data = {
                    id: this.modifyMoneyData.id,
                    money:this.newMoney
                }
                modifyMoney(data).then(res=>{      
                    this.$Message.success(res.msg)                    
                    this.changeModal = false 
                    this.moneyList()     
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            //打开物流信息
            showInfoModal(row) {
                this.logPrams.id = row.id
                this.infoModal = true
                this.listLog()
            },
            listLog(){
                listLog(this.logPrams).then(res=>{
                    this.logtotal = res.data.total
                    this.dataInfoTable = res.data.data
                    this.order_id = this.logPrams.id
                })
            },
            openInfoModal(row){
                this.enterModal = true
                this.formInline.order_id = this.logPrams.id
                this.formInline.id = row.id
                this.formInline.status = row.status
                this.formInline.logistics_time = row.logistics_time
                this.formInline.phone = row.phone
                this.formInline.infos = row.infos
            },
            saveInfoModal(){
                this.enterModal = true
                this.formInline.id = ''
                this.formInline.order_id = ''
                this.formInline.status = ''
                this.formInline.logistics_time = ''
                this.formInline.phone = ''
                this.formInline.infos = ''
            },
            saveEnterModal(){
                if(!this.formInline.status){
                    this.$Message.error('请输入物流状态')
                    return
                }
                if(!this.formInline.logistics_time){
                    this.$Message.error('请输入时间')
                    return
                }
                if(!this.formInline.infos){
                    this.$Message.error('请输入物流信息')
                    return
                }
                if(!this.formInline.id){
                    addLog({order_id:this.order_id,status:this.formInline.status,logistics_time:this.formInline.logistics_time,phone:this.formInline.phone,infos:this.formInline.infos,}).then(res=>{  
                        this.$Message.success(res.msg)  
                        this.enterModal = false
                        this.listLog()                  
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }else{
                    addLog(this.formInline).then(res=>{  
                        this.$Message.success(res.msg)  
                        this.enterModal = false
                        this.listLog()                  
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                }
            },
            timeDateModalChange(value){
                this.formInline.logistics_time = value
            },
            submit(row){
                this.timeId = row.id
                this.$Modal.confirm({
                    title: '警告提示',
                    content: '<p>是否确认完成订单</p>',
                    onOk: () => {
                        modifyStatus({id:this.timeId}).then(res=>{ 
                            this.orderList()
                            this.$Message.success(res.msg)                 
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })
                    },
                });
            },
        },   
    }
</script>

<style lang="scss" scoped>
.input-span{
    margin-right: 10px;
    width: 56px;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
.page{
    background-color: #F5F9FA;
    height: 100%;
}
.head{
    width: 100%;
    height: 70px;
    background-color: #fff;
    margin-bottom: 10px;
    span{
        width: 64px;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        float: left;
        margin: 20px 0 0 20px;
    }
}
.subtitle{
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    .subtitle-item{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}
.content{
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    .time_box {
        cursor: pointer;
        border: 1px solid #B8C4D1;
        height: 24px;
        border-radius: 4px;
    }
    .table_text{
        cursor: pointer;
        width: 28px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3995FE;
        line-height: 50px;
        margin-right: 10px;
    }
    .Page{
        background-color: #fff;
        margin: 20px 0;
        text-align: right;
    }
}
.infoModal_text{
    width: 28px;
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #279EFB;
    line-height: 50px;
    margin: 20px;
    cursor: pointer;
}
.box_auto_heigt {
    max-height: 470px;
    overflow-y:auto;
}
.page_cla {
    display: flex;
    justify-content: right;
    margin-top: 20px;
}
</style>